import React from "react";

const Shipping = () => {
    return (
        <div className="px-4 mx-lg-5 px-lg-5">
            <div className="px-lg-3">
            <h1>Shipping Policy</h1>

            <h2>Shipping Costs</h2>
            <ul>
                <li>Domestic Shipping: We offer <em>free shipping</em> within India for all orders.</li>
                <li>International Shipping: For international orders, shipping costs will vary based on the destination. Please contact our customer support for specific rates.</li>
            </ul>

            <h2>Delivery Times</h2>
            <ul>
                <li>Within India: Delivery times typically range from <strong>same day or may be 2-3 business days</strong> after order processing or less you can see while ordering the product.</li>
                <li>International: International delivery times vary based on the location and shipping method. Please allow up to <strong>14 business days</strong> for international orders.</li>
            </ul>

            <h2>Order Processing</h2>
            <ul>
                <li>Orders are processed within <strong>24 hours</strong> of payment confirmation.</li>
                <li>You will receive an email confirmation with tracking details once your order is shipped.</li>
            </ul>

            <h2>Tracking Your Order</h2>
            <ul>
                <li>You can track your order status by logging into your account on our website.</li>
                <li>Alternatively, you will receive email updates with tracking information.</li>
            </ul>

            <h2>Payment Options</h2>
            <ul>
                <li>We accept <em>online payments</em> via credit/debit cards, net banking, and digital wallets.</li>
                <li>Cash on Delivery (COD) is not available for international orders.</li>
            </ul>

            <h2>Delivery by Us</h2>
            <ul>
                <li>Our dedicated delivery team ensures safe and timely delivery.</li>
                <li>Depending on your location, we may personally deliver your order.</li>
                <li>You'll receive delivery details during the checkout process.</li>
            </ul>

            <h2>Contact Us</h2>
            <ul>
                <li>If you have any questions or need assistance, feel free to reach out to us:</li>
                <li>Email: <em>info@theeliteenterprise.in</em></li>
                <li>Phone: <em>+91 98202 44338</em></li>
            </ul>
            </div>
        </div>
    );
}
export default Shipping;